<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="card">
          <div class="card-header mb-4 text-center">
            <h4 class="font-weight-bolder">Guns Equalization</h4>
            <h5>
              for Line <i>{{ line.name }}</i>
            </h5>
          </div>
          <div
            class="card-body"
            style="padding-top: 0.5rem"
          >
            <div class="row mb-4">
              <h5>Powder Amount Parameters</h5>
              <div class="row">
                <div class="col-4">
                  <label> Minimum Powder Amount </label>
                </div>
                <div class="col-4">
                  <label> Maximum Powder Amount </label>
                </div>
                <div class="col-4">
                  <label> Time Interval [s]</label>
                </div>
              </div>
              <div class="row">
                <div class="col-4">&nbsp; {{ minPowderAmountSetting }}</div>
                <div class="col-4">&nbsp; {{ maxPowderAmountSetting }}</div>
                <div class="col-4">&nbsp; {{ powderOutputTimeInterval }} s</div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="text-start mb-2">Powder Output Measurements</h5>
            </div>
            <div style="overflow-x: auto">
              <div class="table-responsive">
                <table
                  class="table table-sm text-start"
                  style="border-width: 0 !important"
                >
                  <thead>
                    <tr>
                      <th>Gun</th>
                      <th>Empty bag weight</th>
                      <th
                        v-for="index in maxPairs"
                        :key="'header-' + index"
                      >
                        Setting {{ index }} Weight {{ index }}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(gun, gunIndex) in powderoutput_measurements"
                      :key="gunIndex"
                    >
                      <td>{{ gunIndex + 1 }}</td>
                      <td>
                        <input
                          v-model="gun.empty_bag_weight"
                          type="number"
                          class="form-control form-control-sm"
                          :placeholder="`Bag weight [${$store.state.units_system[$store.state.user_data.unit_system].grams}] `"
                          style="max-width: 190px"
                          @change="saveFormProgress"
                        />
                      </td>
                      <td
                        v-for="index in maxPairs"
                        :key="'pair-' + gunIndex + '-' + index"
                      >
                        <div
                          v-if="gun.gun_measurements[index - 1]"
                          class="d-flex"
                        >
                          <input
                            v-model="gun.gun_measurements[index - 1].setting"
                            type="number"
                            :class="`form-control ${
                              gun.gun_measurements[index - 1].setting != '' &&
                              (gun.gun_measurements[index - 1].setting < minPowderAmountSetting ||
                                gun.gun_measurements[index - 1].setting > maxPowderAmountSetting)
                                ? 'is-invalid'
                                : ''
                            } form-control-sm me-1`"
                            placeholder="Setting"
                            style="width: 60px"
                            @blur="PowderAmountSettingInputRangeCheck($event.target.value)"
                          />
                          <input
                            v-model="gun.gun_measurements[index - 1].weight"
                            type="number"
                            class="form-control form-control-sm me-1"
                            placeholder="Weight"
                            style="width: 60px"
                          />
                        </div>
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                            class="btn btn-success"
                            @click="addPair(gunIndex)"
                          >
                            Add
                          </button>
                          <div class="col-1"></div>
                          <button
                            v-if="gun.gun_measurements.length > 2"
                            class="btn btn-danger"
                            @click="deletePair(gunIndex)"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              v-if="saveButtonPressed"
              class="row"
            >
              <div class="col-lg-10">
                <h5 class="">Powder Output Chart</h5>
                <powder-output-chart />
              </div>
              <gun-throughput
                title="Guns Equalization"
                :gun-throughput="gunExpectedOutput"
                :line="line"
                :powder-amount-parameters="powder_amount_parameters"
              />
            </div>
            <div
              v-if="save_button_green"
              class="col-12 text-center mt-4 mb-4"
            >
              <button
                type="button"
                class="mt-4 mb-2 text-center btn"
                :class="save_button_green ? 'bg-gradient-success' : 'bg-gradient-secondary'"
                style="width: 90%"
                @click="successSwal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";

import PowderOutputChart from "../components/PowderOutputChart.vue";
import GunThroughput from "../components/GunThroughput.vue";
import eventBus from "../utils/eventBus";
import { generateMeasurementList } from "@/views/applications/flightpathonsite/utils/utils.js";

export default {
  name: "GunsEqualizationBlueprintLight",
  components: {
    PowderOutputChart,
    GunThroughput,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },

  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      original_powder_throughput_statistics: {
        min_value: null,
        max_value: null,
        diff_value: null,
        std_value: null,
      },

      powderoutput_measurements: [
        {
          empty_bag_weight: null,
          gun_measurements: [
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
          ],
        },
      ],
      line: {
        name: "",
        total_pistols: [0],
      },
      minPowderAmountSetting: 0,
      maxPowderAmountSetting: 200,
      timeInterval: 60,

      powderOutputTimeInterval: 60,
      thickness_measurements: [null, null, null, null, null],
      save_button_green: true,
      saveButtonPressed: false,

      received_powderoutput_data: {
        min_powder_amount_setting: null,
        max_powder_amount_setting: null,
        powderoutput_measurements: null,
        thickness_measurements: Array(5).fill(null),
      },

      max_metric_decimals: 0,
      max_imperial_decimals: 2,

      // Powder Amount Calibration variables
      powder_amount_calibration_measures: null,
      reference_gun_index: null,
      selected_guns_for_powder_amount: [],
      expected_powder_per_minute: null,
      powder_amount_parameters: [],
    };
  },
  computed: {
    gunExpectedOutput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return 0;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(0));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
    maxPairs() {
      let maxPairs = 0;
      this.powderoutput_measurements.forEach(gun => {
        if (gun.gun_measurements.length > maxPairs) {
          maxPairs = gun.gun_measurements.length;
        }
      });
      return maxPairs;
    },
    emptyBenchmarkPowderAmounts() {
      if (this.benchmark_stage_data.gun_settings == null) {
        return true;
      }
      const powderAmountSettings = this.benchmark_stage_data.gun_settings.map(item => item.powder);
      const emptyPowderAmounts = powderAmountSettings.some(
        powder_amount_setting => powder_amount_setting == "" || powder_amount_setting == null,
      );
      return emptyPowderAmounts;
    },
    emptyPowderAmountMeasurements() {
      const empty_powder_amount_measurements = this.powderoutput_measurements.some(gun =>
        gun.gun_measurements.some(pair => pair.setting == "" || pair.weight == ""),
      );
      return empty_powder_amount_measurements;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    generateMeasurementList,
    async getData() {
      await Promise.all([this.getBlueprintLightLine(), this.getLatestPowderThroughput()]);

      this.generateGuns(this.line.total_pistols[0]);
      // this.populatePowderAmountMeasures();
    },
    generateGuns(total_guns) {
      this.powderoutput_measurements = Array.from({ length: total_guns }, () => ({
        gun_measurements: [
          {
            setting: "",
            weight: "",
          },
          {
            setting: "",
            weight: "",
          },
        ],
      }));
    },
    async getLatestPowderThroughput() {
      try {
        const response = await axios.get("/api/v1/blueprintlight/lastvisitpowderthroughput/" + this.lineId + "/");
        this.expected_powder_per_minute = response.data.last_powder_throughput;
        this.minPowderAmountSetting = response.data.min_powder_amount_setting;
        this.maxPowderAmountSetting = response.data.max_powder_amount_setting;
      } catch (error) {
        console.error(error);
        if (error.response && (error.response.status === 404 || error.response.status === 500)) {
          this.$swal({
            title: "Powder Throughput could not be retrieved.",
            text: error.response.data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    },
    async getPowderOutputChartDataset() {
      let body = {
        min_powder_amount_setting: this.minPowderAmountSetting,
        max_powder_amount_setting: this.maxPowderAmountSetting,
        time_interval: this.powderOutputTimeInterval,
        powderoutput_measurements: this.powderoutput_measurements.map(gun => ({
          ...gun,
          empty_bag_weight: gun.empty_bag_weight == null || gun.empty_bag_weight === "" ? 0 : gun.empty_bag_weight,
        })),
        cumulative_powderoutput_measurements: true,
      };

      const response = await axios.post("/api/v1/onsite/powderoutputchart/", body);

      this.$store.state.powderOutputChart = response.data.powderoutput_measurements;
    },
    async computePowderAmountParameterResults() {
      if (this.emptyPowderAmountMeasurements) {
        return;
      }

      try {
        let body = {
          line: this.lineId,
          time_interval: this.powderOutputTimeInterval,
          measures_list: this.generateMeasurementList(this.powderoutput_measurements),
          powder_per_minute: this.expected_powder_per_minute,
          gun_idx: null,
          powder_amount_param: null,
          cumulative_powderoutput_measurements: true,
        };

        let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
        this.powder_amount_parameters = response.data.powder_amount_params;
        this.roundPowderAmountParameters();
      } catch (error) {
        console.error(error);
      }
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    checkPowderAmountResultsRangeAlert() {
      let calibration_out_of_range_popup_shown = false;
      for (let index = 0; index < this.powder_amount_parameters.length; index++) {
        if (
          this.powder_amount_parameters[index] !== null &&
          this.powder_amount_parameters[index] !== "" &&
          (this.powder_amount_parameters[index] < this.minPowderAmountSetting ||
            this.powder_amount_parameters[index] > this.maxPowderAmountSetting)
        ) {
          if (!calibration_out_of_range_popup_shown) {
            this.$swal({
              title: "Calibration Out of Range",
              text: `${"The resulting Powder Amount setting is out of range for Gun"} ${index + 1}.\n Min: ${
                this.minPowderAmountSetting
              }, Max: ${this.maxPowderAmountSetting}.
              ${"Obtained Parameter"}: ${this.powder_amount_parameters[index]}`,
              icon: "error",
              confirmButtonText: "OK",
            });
            calibration_out_of_range_popup_shown = true;
          }
        }
      }
    },
    populatePowderAmountMeasures() {
      const total_guns = this.line.total_pistols[0];
      this.powderoutput_measurements = Array.from({ length: total_guns }, (_, index) => ({
        gun_measurements: [
          {
            setting: 3.1,
            weight: 23,
          },
          {
            setting: 5,
            weight: (20 * (2 + index)) / (index + 1),
          },
        ],
      }));
    },

    isPowderOutputMeasurementsFilled() {
      let total_empty_measures = this.powderoutput_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting !== "" && gun_measurment.weight !== ""),
          ) &&
        this.expected_powder_per_minute !== null
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting === "" && gun_measurment.weight === ""),
          ) ||
        this.expected_powder_per_minute === null
      ) {
        return PROGRESS_STATUS_IN_PROGRESS;
      } else {
        return PROGRESS_STATUS_PENDING;
      }
    },
    async getBlueprintLightLine() {
      try {
        const response = await axios.get("api/v1/blueprintlight/line/" + this.lineId + "/");
        this.line = response.data;
        this.line.total_pistols = JSON.parse(this.line.total_pistols);
        this.$store.state.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    // async getGunsEqualizationData() {
    //   if (!this.$route.params.gunsEqualizationId) {
    //     return;
    //   }
    //   try {
    //     const response = await axios.get(
    //       "api/v1/blueprintlight/powderoutputequalization/" + this.$route.params.gunsEqualizationId,
    //     );
    //     this.received_powderoutput_data = response.data;
    //     this.powderoutput_measurements = this.received_powderoutput_data.powderoutput_measurements;
    //     this.expected_powder_per_minute = this.received_powderoutput_data.gun_powder_throughput;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    async saveFormProgress() {
      const formData = {
        powder_amount_measurements: this.powderoutput_measurements,
        gun_powder_throughput: this.expected_powder_per_minute,
      };

      try {
        await axios.post("api/v1/blueprintlight/powderoutputequalization/", formData);
      } catch (error) {
        console.error(error);
      }
    },
    PowderAmountSettingInputRangeCheck(target_value) {
      if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting < this.minPowderAmountSetting),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be greater than the minimum allowed of ${this.minPowderAmountSetting}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));
        return;
      } else if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting > this.maxPowderAmountSetting),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be smaller than the maximum allowed of ${this.maxPowderAmountSetting}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));

        return;
      }
    },
    removePowderMeasurementInput(target_value) {
      this.powderoutput_measurements.map(gun =>
        gun.gun_measurements.map(pair => {
          if (pair.setting == target_value) {
            pair.setting = "";
          }
        }),
      );
    },
    async successSwal() {
      try {
        this.save_button_green = false;
        this.saveButtonPressed = true;

        await this.computePowderAmountParameterResults();
        await this.showPowderOutputGraph();

        await this.$swal({
          title: "Gun Equlization success",
          text: "Powder amounts fine tune adjustment calculation successful",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
      }
    },
    addPair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    deletePair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.pop();
    },
    async showPowderOutputGraph() {
      if (this.isPowderOutputMeasurementsFilled()) {
        await this.getPowderOutputChartDataset();
        eventBus.emit("draw-powderoutput-chart");
      }
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.container-fluid {
  padding-top: 20px;
}
</style>
