<template>
  <div>
    <div class="py-5 container-fluid">
      <div id="topinfo">
        <div class="row mt-4 justify-content-center">
          <div class="col-12">
        <div class="mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 text-start mt-3 mb-0">
          <button
            class="btn btn-outline-secondary btn-sm px-3 ms-2 mt-1"
            @click="$router.push(`/applications/onsite/linevisits/${lineId}`)"
          >
            <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" />
            Go back to workflows
          </button>
        </div>
          <div class="mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 d-flex flex-column card card-body blur shadow-blur">
            <div class="card-header text-center">
              <h4 class="font-weight-bolder">Powder Saving</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <p class="mr-3 mt-n4">
                  Powder Saving step suggests a reduction on the powder amount settings, which directly implies real
                  powder savings. This powder save is made possible by the reciprocator optimization improved powder
                  application.
                </p>
                <hr class="horizontal dark" />
              </div>
              <div v-if="!isFirstAdjustement">
                <div class="row">
                  <div v-if="gunThroughput !== null" class="col-lg-7">
                    <gun-throughput
                      title="Adjusted Powder throughput"
                      :gun-throughput="gunThroughput"
                      :min-gun-throughput="received_powderoutput_data.min_powder_throughput"
                      :line="line"
                      :powder-amount-parameters="powder_amount_parameters"
                      :editable-gun-throughput="isInLastPage"
                      :iteration-zero-gun-output="parseFloat(GunsEqualizationAveragePowderThroughput)"
                      @update-gun-throughput="updateGunThroughput($event, false)"
                      @save-gun-throughput="updateGunThroughput($event, true)"
                    />
                  </div>
                  <div class="col-lg-5 justify-content-center">
                    <powder-saving-chart
                      :dataset-zero="
                        received_powderoutput_data.last_average_powder_throughput_per_gun
                          ? received_powderoutput_data.last_average_powder_throughput_per_gun
                          : 0
                      "
                      :dataset-one="expected_powder_per_minute ? expected_powder_per_minute : 0"
                    />
                  </div>
                </div>
              </div>
              <div v-if="isFirstAdjustement">
                <div class="card-header text-center">
                  <h5 class="font-weight-bolder">Calculate adjusted gun throughput and powder amount settings</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <mini-statistics-card
                        :title="'Current Average Gun Throughput'"
                        :value="`${GunsEqualizationAveragePowderThroughput} [g/min]`"
                        :icon="{ component: 'ni ni-chart-bar-32', background: 'bg-gradient-info' }"
                        class-content="px-2"
                      />
                    </div>
                    <div class="col-6">
                      <label
                        for="percentileInput"
                        class="form-label"
                        >Gaussian percentile of margin (optional):
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-container="body"
                          data-animation="true"
                          :title="'Default value is 0.1. Bigger values will make a more conservative estimate of how much the powder output can be reduced.'"
                        ></i>
                      </label>
                      <input
                        id="percentileInput"
                        v-model="percentile"
                        type="number"
                        class="form-control"
                        min="0"
                        max="100"
                        step="0.1"
                      />
                    </div>
                    <div class="col-12 text-center">
                      <button
                        class="btn btn-primary"
                        @click="nextPowderSavingIteration();"
                      >
                        Calculate adjusted Gun Throughput
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isFirstAdjustement">
                <hr class="horizontal dark" />
                <div class="row mt-4">
                  <div class="col-5">
                    <h5>
                      Thickness Measurements [{{
                        this.$store.state.units_system[$store.state.user_data.unit_system].thickness
                      }}]
                    </h5>
                    <div class="table-responsive">
                      <table class="table table-sm text-center text-xs">
                        <thead>
                          <tr>
                            <th>Measurement</th>
                            <th>Thickness</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(_, index) in thickness_measurements"
                            :key="index"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>
                              <input
                                v-model="thickness_measurements[index]"
                                type="number"
                                class="form-control text-center"
                                :disabled="(!more_powder_saving_iterations_to_do || !isInLastPage) && expected_powder_per_minute === powdersaving_iteration_params.powder_throughput_per_gun"
                                style="width: 50%; margin: 0 auto"
                                min="0"
                                @change="checkLastRow();"
                                @keydown="numericOnly"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button
                        class="mt-2 btn btn-primary"
                        @click="
                          showThicknessGraph();
                          patchPowderSavingIterationParameters(powdersaving_iteration_params.powder_throughput_per_gun, thickness_measurements, more_powder_saving_iterations_to_do);
                        "
                      >
                        Show Thickness Distribution Chart
                      </button>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="col-12 mt-3">
                      <gaussian-chart
                        title="Thickness Distribution Chart"
                        chart-name="gaussianPowderSavingChart"
                      />
                      <thickness-measurement-statistic
                        :benchmark-thickness-measurements="benchmark_stage_data.thickness_measurements"
                        :powderoutput-thickness-measurements="received_powderoutput_data.thickness_measurements"
                        :reciprocator-thickness-measurements="last_thickness_measurements"
                        :powdersaving-thickness-measurements="statistics_thickness_measurements"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="powderSavingIterations.length > 1" class="text-center mt-4">
                  <h6>Explore history of Powder Throughput adjustments</h6>
                  <soft-pagination
                    class="pagination-lg justify-content-center"
                    color="primary"
                    aria-label="Experiment thickness measures input pages"
                  >
                    <soft-pagination-item
                      prev
                      :disabled="isInFirstPage"
                      @click="onClickPrevPage"
                    />
                    <soft-pagination-item
                      v-for="(powder_saving_iteration, index) in powderSavingIterations"
                      :key="(index + 1).toFixed(0)"
                      :label="(index + 1).toFixed(0)"
                      :active="currentPage === (index + 1)"
                      @click="updateCurrentPage(index + 1)"
                    />
                    <soft-pagination-item
                      next
                      :disabled="isInLastPage"
                      @click="onClickNextPage"
                    />
                  </soft-pagination>
                </div>
                <div class="row mt-6">
                  <div class="col-8">
                    <button
                      :class="`btn ${
                        valuesUpdated && more_powder_saving_iterations_to_do && isInLastPage ? 'btn-success' : 'btn-secondary'
                      }`"
                      style="width: 100%"
                      :disabled="!more_powder_saving_iterations_to_do || !isInLastPage"
                      @click="nextPowderSavingIteration();"
                    >
                      {{
                        !isInLastPage ? "SHOWING HISTORY" :
                        more_powder_saving_iterations_to_do
                          ? "COMPUTE NEXT POWDER THROUGHPUT ADJUSTMENT"
                          : "FINISHED. NO MORE POWDER ADJUSTMENTS RECOMMENDED."
                      }}
                    </button>
                  </div>
                  <div
                    class="col-4"
                  >
                    <button
                      class="btn btn-warning"
                      style="width: 100%"
                      @click="cleanPowderSavingIterations()"
                    >
                      Clean history & restart process
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <stepper-line-visit
        :line-id="lineId"
        :visit="visit"
      />
    </div>
  </div>
</template>

<script>
import StepperLineVisit from "./components/StepperLineVisit.vue";
import setTooltip from "@/assets/js/tooltip.js";
import axios from "axios";
import {
  generateX,
  gaussian,
  generateY,
  calculateMu,
  calculateSigma,
  generateXforMultipleMuSigma,
  generateYwithXvalues,
} from "./utils/gaussianchart";

import {
  useGetVisit,
  useGetBenchmark,
  measurementsMin,
  measurementsMax,
  showStatistics,
  numericOnly,
  generateMeasurementList,
} from "./utils/utils";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowRotateLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useGetLine, useGetPowders } from "@/views/composables.js";
import eventBus from "./utils/eventBus";
import GaussianChart from "@/views/applications/flightpathonsite/components/GaussianChart.vue";
import GunThroughput from "./components/GunThroughput.vue";
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";
import PowderSavingChart from "./components/PowderSavingChart.vue";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";
import ThicknessMeasurementStatistic from "./components/ThicknessMeasurementStatistic.vue";

library.add([faArrowRotateLeft, faCircleChevronRight]);

export default {
  name: "PowderOutput",
  components: {
    FontAwesomeIcon,
    GaussianChart,
    GunThroughput,
    MiniStatisticsCard,
    PowderSavingChart,
    SoftPagination,
    SoftPaginationItem,
    StepperLineVisit,
    ThicknessMeasurementStatistic,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    const { line, getLine } = useGetLine();
    const { benchmark_stage_data, getBenchmarkData } = useGetBenchmark();
    const { powders, getPowders } = useGetPowders();

    return {
      visit,
      getVisit,
      benchmark_stage_data,
      getBenchmarkData,
      line,
      getLine,
      powders,
      getPowders,
    };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      selectedPowder: null,
      thickness_measurements: [null, null, null, null, null],
      last_thickness_measurements: [null, null, null, null, null],
      last_page_edited_thickness_measures: [null, null, null, null, null],
      percentile: 0.1,
      received_powderoutput_data: {
        min_powder_amount_setting: null,
        max_powder_amount_setting: null,
        time_interval: 60,
        powder_amount_measurements: null,
        thickness_measurements: Array(5).fill(null),
      },
      received_powdersaving_data: {
        thickness_measurements: Array(5).fill(null),
      },
      statistics_thickness_measurements: [null,null,null,null,null],
      // Powder Amount Calibration variables
      expected_powder_per_minute: null,
      more_powder_saving_iterations_to_do: true,
      powdersaving_iteration_params: {
        powder_throughput_per_gun: null,
      },
      lastPowderSavingIterationId: null,
      invalid_powder_amount_shown: false,
      powderSavingIterations: [],
      powder_amount_parameters: [],
      // Pagination variables
      currentPage: 1,
    };
  },
  computed: {
    isFirstAdjustement() {
      let is_first_adjustment = (
        this.lastPowderSavingIterationId == null ||
        this.lastPowderSavingIterationId == "" ||
        this.lastPowderSavingIterationId == undefined
      );
      return is_first_adjustment;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.powderSavingIterations.length;
    },
    emptyThicknessMeasurements() {
      if (
        this.thickness_measurements == null ||
        this.thickness_measurements == undefined ||
        this.thickness_measurements.length < 2 ||
        this.thickness_measurements.every(m => m == null || m == "")
      ) {

        return true;
      }
      return false;
    },
    GunsEqualizationAveragePowderThroughput() {
      return this.received_powderoutput_data.last_average_powder_throughput_per_gun
        ? this.received_powderoutput_data.last_average_powder_throughput_per_gun.toFixed(1)
        : null;
    },
    valuesUpdated() {
      const isThicknessNotEqual =
        JSON.stringify(this.thickness_measurements) !==
        JSON.stringify(this.received_powdersaving_data.thickness_measurements);

      return isThicknessNotEqual;
    },
    gunThroughput: {
      get() {
        if (this.expected_powder_per_minute == null || this.expected_powder_per_minute == "") {
          return null;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.expected_powder_per_minute * this.$store.state.conversion_factors.g_to_oz).toFixed(2),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.expected_powder_per_minute.toFixed(1));
        } else {
          return this.expected_powder_per_minute;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.expected_powder_per_minute = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.expected_powder_per_minute = value / this.$store.state.conversion_factors.g_to_oz;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.expected_powder_per_minute = value;
          }
        }
      },
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    measurementsMin,
    measurementsMax,
    showStatistics,
    numericOnly,
    generateMeasurementList,
    async getData() {
      await Promise.all([
        this.getVisit(this.visitId),
        this.getLine(this.lineId),
        this.getPowderOutputStageData(),
        this.getBenchmarkData(this.visitId, 0),
        this.getPowders(),
        this.getPowderSavingStageData(),
        this.getLastReciprocatorStageThicknessMeasures(),
      ]);

      this.powderSelectedCheck();

      await this.getPowderSavingIterations();
      await this.getPowderSavingIteration();
      await this.getInitialPowderThroughput();

      if (!this.isFirstAdjustement) {
        await this.computePowderAmountParameters();
        this.drawBarChart();
        this.showThicknessGraph();
        this.updateFormProgressStatus();
      }
    },
    clearThicknessMeasurementsArray() {
      this.thickness_measurements = [null, null, null, null, null];
    },
    cleanThicknessMeasurementsArray(array) {
      return array.filter(m => m !== null && m !== "");
    },
    async getPowderSavingIteration() {
      if (this.isFirstAdjustement) {
        return;
      }
      try {
        const response = await axios.get(
          `/api/v1/onsite/powdersavingiterationparametersdetail/${this.lastPowderSavingIterationId}/`,
        );
        this.powdersaving_iteration_params = JSON.parse(JSON.stringify(response.data));
        this.more_powder_saving_iterations_to_do = this.powdersaving_iteration_params.more_powder_saving_iterations_to_do;
        this.expected_powder_per_minute = this.powdersaving_iteration_params.powder_throughput_per_gun;

        if (!this.more_powder_saving_iterations_to_do) {
          this.thickness_measurements = this.powdersaving_iteration_params.thickness_measurements;
        }
        if (this.emptyThicknessMeasurements) {
          this.clearThicknessMeasurementsArray();
        }
      } catch (error) {
        console.error(error);
      }
    },
    onClickPrevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    onClickNextPage() {
      if (this.currentPage < this.powderSavingIterations.length) {
        this.currentPage += 1;
      }
    },
    powderSelectedCheck() {
      if (this.benchmark_stage_data.powder !== null) {
        this.selectedPowder = this.benchmark_stage_data.powder;
        return;
      }

      this.$swal({
        title: "No Powder Selected",
        text: "Please select a powder in Benchmark stage to proceed.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then(() => {
        this.$router.push({ name: "BenchmarkAndTarget" });
      });
    },
    async getLastReciprocatorStageThicknessMeasures() {
      try {
        const response = await axios.get("/api/v1/onsite/lastthicknessmeasures/" + this.visitId + "/");
        if (response.data !== null) {
          this.last_thickness_measurements = response.data.last_thickness_measurements;

          if (this.cleanThicknessMeasurementsArray(this.last_thickness_measurements).length < 2) {
            this.$swal({
              title: "Couldn't retrive last thickness measurements from Reciprocator Optimization stage",
              text: "Please provide thickness measurements after calculating reciprocator optimization.",
              icon: "warning",
              confirmButtonText: "OK",
            }).then(() => {
              this.$router.push({ name: "ReciprocatorOptimization" });
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getPowderSavingIterations() {
      try {
        const response = await axios.get(
          `/api/v1/onsite/powdersavingiterationparameters/${this.visitId}/${this.selectedPowder}/`,
        );
        this.powderSavingIterations = response.data.slice(1);
        console.log("Powder Saving Iterations: ", this.powderSavingIterations);
        this.currentPage = this.powderSavingIterations.length;
      } catch (error) {
        console.error(error);
      }
    },
    async computePowderAmountParameters() {
      if (this.isPowderOutputMeasurementsFilled() && this.isValidExpectedThroughput()) {
        try {
          let body = {
            line: this.lineId,
            time_interval: this.received_powderoutput_data.time_interval,
            measures_list: this.generateMeasurementList(this.received_powderoutput_data.powder_amount_measurements),
            powder_per_minute: this.expected_powder_per_minute,
            gun_idx: null,
            powder_amount_param: null,
            cumulative_powderoutput_measurements: true,
          };

          let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
          this.powder_amount_parameters = response.data.powder_amount_params;
          this.roundPowderAmountParameters();
        } catch (error) {
          console.error(error);
        }
      }
      this.checkPowderAmountParameters();
    },
    checkPowderAmountParameters() {
      const negative_powder_amounts = this.powder_amount_parameters.some(powder_amount_param => powder_amount_param <= 0)
      if (negative_powder_amounts && !this.invalid_powder_amount_shown) {
        this.$swal({
          title: "Invalid Powder Amount Parameter results",
          text: `The Powder Throughput ${this.expected_powder_per_minute.toFixed(1)} [g/min] results in invalid/negative powder amount parameters. Review your powder saving process.`,
          icon: "error",
          confirmButtonText: "OK",
        });
        this.invalid_powder_amount_shown = true;
      }
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    async getPowderOutputStageData() {
      try {
        let response = await axios.get("/api/v1/onsite/powderoutput/" + this.visitId);
        this.received_powderoutput_data = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
      this.$store.state.isLoaded = false;
    },
    async getPowderSavingStageData() {
      try {
        const response = await axios.get("/api/v1/onsite/powdersaving/" + this.visitId + "/");
        if (response.data !== null && response.data !== undefined) {
          this.received_powdersaving_data = JSON.parse(JSON.stringify(response.data));

          if (
            this.received_powdersaving_data.last_powder_saving_iteration !== null &&
            this.received_powdersaving_data.last_powder_saving_iteration !== "" &&
            this.received_powdersaving_data.last_powder_saving_iteration !== undefined
          ) {
            this.lastPowderSavingIterationId = this.received_powdersaving_data.last_powder_saving_iteration;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    isValidExpectedThroughput() {
      let is_valid_expected_throughput =
        this.expected_powder_per_minute !== null &&
        this.expected_powder_per_minute !== "" &&
        this.expected_powder_per_minute > 0;

      return is_valid_expected_throughput;
    },
    async updateGunThroughput(new_powder_throughput, save_changes) {
      this.expected_powder_per_minute = new_powder_throughput;

      if (!this.isValidExpectedThroughput()) {
        return;
      }

      if (save_changes) {
        await this.patchPowderSavingIterationParameters(
          this.expected_powder_per_minute,
          this.thickness_measurements,
          this.more_powder_saving_iterations_to_do,
        );
      }

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    isPowderOutputMeasurementsFilled() {
      let total_empty_measures = this.received_powderoutput_data.powder_amount_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    async getInitialPowderThroughput() {
      if (
        this.powdersaving_iteration_params !== null &&
        this.powdersaving_iteration_params.powder_throughput_per_gun !== undefined &&
        this.powdersaving_iteration_params.powder_throughput_per_gun !== null
      ) {
        this.expected_powder_per_minute = this.powdersaving_iteration_params.powder_throughput_per_gun;
      } else {
        this.expected_powder_per_minute = this.received_powderoutput_data.last_average_powder_throughput_per_gun;
      }

      if (
        this.expected_powder_per_minute == null ||
        this.expected_powder_per_minute == undefined ||
        this.expected_powder_per_minute == "" ||
        this.expected_powder_per_minute == 0
      ) {
        this.$swal({
          title: "Couldn't retrieve Powder Throughput",
          text: "Please provide Powder Amount Settings in Benchmark and Powder Output Measures in Powder Equalization.",
          icon: "warning",
          confirmButtonText: "OK",
        }).then(() => {
          this.$router.push({ name: "BenchmarkAndTarget" });
        });
      }
    },
    async nextPowderSavingIteration() {
      await this.getNextOptimizedPowderThroughput();
      await this.computePowderAmountParameters();

      this.drawBarChart();
      this.showThicknessGraph();
      this.updateFormProgressStatus();
    },
    async getNextOptimizedPowderThroughput() {
      try {
        let thickness_measurements = null;
        if (this.isFirstAdjustement) {
          thickness_measurements = this.cleanThicknessMeasurementsArray(this.last_thickness_measurements);

          await this.createPowderSavingIterationParameters(this.expected_powder_per_minute);
        } else {
          if (this.cleanThicknessMeasurementsArray(this.thickness_measurements).length < 2) {
            this.$swal({
              title: "Empty Thickness Measurements",
              text: "Please provide non Empty thickness measurements to proceed to calculate next optimized powder amount settings.",
              icon: "warning",
              confirmButtonText: "OK",
            });
            return;
          }
          thickness_measurements = this.cleanThicknessMeasurementsArray(this.thickness_measurements);
        }

        const gaussian_mean = this.calculateMu(thickness_measurements);
        const gaussian_std = this.calculateSigma(thickness_measurements, gaussian_mean);
        const body = {
          gaussian_mean: gaussian_mean,
          gaussian_std: gaussian_std,
          current_powder_throughput: this.expected_powder_per_minute,
          min_desired_thickness: this.benchmark_stage_data.minimum_target_thickness,
          min_powder_throughput: this.received_powderoutput_data.min_powder_throughput,
          percentile: this.percentile,
        };
        const response = await axios.post("/api/v1/onsite/nextoptimizedpowderthroughput/", body);
        const next_powder_throughput = response.data.next_powder_throughput;
        const more_powder_saving_iterations_to_do = response.data.more_powder_saving_iterations_to_do;

        console.log("Min powder throughput: ", this.received_powderoutput_data.min_powder_throughput);
        console.log("Next powder throughput received: ", next_powder_throughput);
        console.log("More Powder Saving Iterations to do:", more_powder_saving_iterations_to_do);

        await this.patchPowderSavingIterationParameters(this.expected_powder_per_minute, thickness_measurements, more_powder_saving_iterations_to_do);

        if (more_powder_saving_iterations_to_do) {
          await this.createPowderSavingIterationParameters(next_powder_throughput);
          this.clearThicknessMeasurementsArray();

          this.$swal({
            title: "Powder Throughput has been adjusted.",
            text: "Update your Powder Amount Settings and take thickness measurements again.",
            icon: "info",
            confirmButtonText: "OK",
          });
        } else {
          this.$swal({
            title: "No more Powder Throughput adjustements are needed",
            text: "No more Powder Saving steps are recommended. You have completed the Powder Saving process.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
        await this.getPowderSavingIterations();
        await this.getPowderSavingIteration();

      } catch (error) {
        console.error(error);
      }
    },
    async createPowderSavingIterationParameters(powder_throughput_per_gun) {
      const response = await axios.post(
        `/api/v1/onsite/powdersavingiterationparameters/${this.visitId}/${this.selectedPowder}/`,
        {
          visit: this.visitId,
          powder: this.selectedPowder,
          powder_throughput_per_gun: powder_throughput_per_gun,
        },
      );

      if (response.status == 201) {
        await axios.patch(`/api/v1/onsite/powdersaving/${this.visitId}/`, {
          last_powder_saving_iteration: response.data.id,
        });
        this.lastPowderSavingIterationId = response.data.id;
        this.powdersaving_iteration_params = JSON.parse(JSON.stringify(response.data));
      }
    },
    async patchPowderSavingIterationParameters(expected_powder_per_minute, thickness_measurements, more_powder_saving_iterations_to_do) {
      try {
        if (expected_powder_per_minute == null || expected_powder_per_minute == "") {
          return;
        }

        const response = await axios.patch(
          `/api/v1/onsite/powdersavingiterationparametersdetail/${this.lastPowderSavingIterationId}/`,
          {
            powder_throughput_per_gun: expected_powder_per_minute,
            thickness_measurements: thickness_measurements,
            more_powder_saving_iterations_to_do: more_powder_saving_iterations_to_do,
          },
        );
        this.powdersaving_iteration_params = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
    },
    async cleanPowderSavingIterations() {
      const PROGRESS_STATUS_PENDING = "Pending";
      try {

        const result = await this.$swal({
          title: "Are you sure?",
          text: "This will remove all adjustment steps.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, clean history",
          customClass: {
            confirmButton: "btn btn-warning w-40 px-1 m-2",
            cancelButton: "btn bg-gradient-danger w-40 px-1 m-2",
          },
          reverseButtons: true,
          buttonsStyling: false,
        });

        if (result.isConfirmed) {
          const response = await axios.delete(
            `/api/v1/onsite/powdersavingiterationparameters/${this.visitId}/${this.selectedPowder}/`
          );

        if (response.status == 204) {
          await axios.patch(`/api/v1/onsite/powdersaving/${this.visitId}/`, {
            progress_status: PROGRESS_STATUS_PENDING,
          });
        }

        this.$swal({
          title: "Powder Saving Iterations Cleaned",
          text: "The powder saving iterations history have been successfully cleaned. Page will be reloaded.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          window.location.reload();
        });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateCurrentPage(index) {
      if (this.isInLastPage) {
        this.last_page_edited_thickness_measures = JSON.parse(JSON.stringify(this.thickness_measurements))
      }
      this.currentPage = index;

      this.thickness_measurements = this.powderSavingIterations[this.currentPage - 1].thickness_measurements;
      this.expected_powder_per_minute = this.powderSavingIterations[this.currentPage - 1].powder_throughput_per_gun;
      this.more_powder_saving_iterations_to_do = this.powderSavingIterations[this.currentPage - 1].more_powder_saving_iterations_to_do;

      if (this.emptyThicknessMeasurements) {
        if (this.isInLastPage) {
          this.thickness_measurements = this.last_page_edited_thickness_measures;
        }
        else {
          this.clearThicknessMeasurementsArray();
        }
      }

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.isValidExpectedThroughput() &&
        this.thickness_measurements.filter(m => m !== null && m !== "").length >= 2
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (this.isValidExpectedThroughput()) {
        return PROGRESS_STATUS_IN_PROGRESS;
      }

      return PROGRESS_STATUS_PENDING;
    },
    async updateFormProgressStatus() {
      try {
        await axios.patch("/api/v1/onsite/powdersaving/" + this.visitId + "/", {
          progress_status: this.getFormProgressStatus(),
        });
        await this.getVisit(this.visitId);
      } catch (error) {
        console.error(error);
      }
    },
    checkLastRow() {
      while (this.thickness_measurements.length < 5) {
        this.thickness_measurements.push("");
      }

      if (this.thickness_measurements.every(m => m !== null && m !== "" && m !== 0)) {
        this.thickness_measurements.push("");
      } else if (
        (this.thickness_measurements[this.thickness_measurements.length - 1] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 1] == "") &&
        (this.thickness_measurements[this.thickness_measurements.length - 2] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 2] == "") &&
        this.thickness_measurements.length > 5
      ) {
        this.thickness_measurements.pop();
      }
    },
    generateXLabels() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      const benchmarkMu = this.calculateMu(this.benchmark_stage_data.thickness_measurements);
      const benchmarkSigma = this.calculateSigma(this.benchmark_stage_data.thickness_measurements, benchmarkMu);

      const powderOutputMu = this.calculateMu(this.received_powderoutput_data.thickness_measurements);
      const powderOutputSigma = this.calculateSigma(
        this.received_powderoutput_data.thickness_measurements,
        powderOutputMu,
      );

      const reciprocatorMu = this.calculateMu(this.received_powderoutput_data.thickness_measurements);
      const reciprocatorSigma = this.calculateSigma(
        this.received_powderoutput_data.thickness_measurements,
        powderOutputMu,
      );

      this.$store.state.gaussianPowderSavingChart.labels = this.generateXforMultipleMuSigma([
        [mu, sigma],
        [benchmarkMu, benchmarkSigma],
        [powderOutputMu, powderOutputSigma],
        [reciprocatorMu, reciprocatorSigma],
      ]);

      const Xarray = this.$store.state.gaussianPowderSavingChart.labels.map(num => parseFloat(num));

      this.$store.state.minTargetThickness = Xarray.findIndex(
        num => num > this.benchmark_stage_data.minimum_target_thickness,
      );
      this.$store.state.maxTargetThickness = Xarray.findIndex(
        num => num > this.benchmark_stage_data.maximum_target_thickness,
      );
    },
    benchmarkThicknessMeasurementsUpdateChartData() {
      if (
        this.benchmark_stage_data.thickness_measurements == null ||
        this.benchmark_stage_data.thickness_measurements == undefined ||
        this.benchmark_stage_data.thickness_measurements.length <= 2
      ) {
        return;
      }

      const benchmarkMu = this.calculateMu(this.benchmark_stage_data.thickness_measurements);
      const benchmarkSigma = this.calculateSigma(this.benchmark_stage_data.thickness_measurements, benchmarkMu);

      this.$store.state.gaussianPowderSavingChart.datasets[0] = {
        label: "Initial Benchmark",
        data: this.generateYwithXvalues(
          benchmarkSigma,
          benchmarkMu,
          this.$store.state.gaussianPowderSavingChart.labels,
        ),
      };
    },
    powderOutputThicknessMeasurementsUpdateChartData() {
      if (
        this.received_powderoutput_data.thickness_measurements == null ||
        this.received_powderoutput_data.thickness_measurements == undefined ||
        this.received_powderoutput_data.thickness_measurements.some(m => m == null || m == "") ||
        this.received_powderoutput_data.thickness_measurements.length <= 2
      ) {
        return;
      }

      const powderOutputMu = this.calculateMu(this.received_powderoutput_data.thickness_measurements);
      const powderOutputSigma = this.calculateSigma(
        this.received_powderoutput_data.thickness_measurements,
        powderOutputMu,
      );

      const arr = this.$store.state.gaussianPowderSavingChart.labels.map(num => parseFloat(num));

      let index = arr.findIndex(num => num > this.benchmark_stage_data.minimum_target_thickness);
      let index_max = arr.findIndex(num => num > this.benchmark_stage_data.maximum_target_thickness);

      this.$store.state.minTargetThickness = index > 0 ? index - 1 : index;
      this.$store.state.maxTargetThickness = index_max > 0 ? index_max - 1 : arr.length - 1;

      this.$store.state.gaussianPowderSavingChart.datasets[1] = {
        label: "Guns Equalization stage",
        data: this.generateYwithXvalues(
          powderOutputSigma,
          powderOutputMu,
          this.$store.state.gaussianPowderSavingChart.labels,
        ),
      };
    },
    thicknessMeasurementsReciprocatorStageUpdateChartData() {
      const mu = this.calculateMu(this.last_thickness_measurements);
      const sigma = this.calculateSigma(this.last_thickness_measurements, mu);

      this.$store.state.gaussianReciprocatorMu = mu;
      this.$store.state.gaussianReciprocatorSigma = sigma;

      this.$store.state.gaussianPowderSavingChart.datasets[2] = {
        label: "Reciprocator stage",
        data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianPowderSavingChart.labels),
      };
    },
    prevAdjustmentsThicknessMeasurementsUpdateChartData() {
      if (this.currentPage < 1 || this.powderSavingIterations.length < 1) {
        return;
      }

      for (let idx = 0; idx < this.powderSavingIterations.length - 1; idx++) {
        const thickness_measurements = this.powderSavingIterations[idx].thickness_measurements;
        const mu = this.calculateMu(thickness_measurements);
        const sigma = this.calculateSigma(thickness_measurements, mu);

        this.$store.state.gaussianPowderSavingChart.datasets[3 + idx] = {
          label: `Powder Adjust #${idx + 1}`,
          data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianPowderSavingChart.labels),
        };
      }
    },
    thicknessMeasurementsUpdateChartData() {
      if (this.emptyThicknessMeasurements) {
        return;
      }
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      const total_iterations = this.powderSavingIterations.length
      const last_dataset_idx = parseFloat(2 + total_iterations);

      this.$store.state.gaussianPowderSavingChart.datasets[last_dataset_idx] = {
        label: `Powder Adjust #${this.powderSavingIterations.length}`,
        data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianPowderSavingChart.labels),
      };
    },
    addPair(gunIndex) {
      this.powder_amount_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    deletePair(gunIndex) {
      this.powder_amount_measurements[gunIndex].gun_measurements.pop();
    },
    drawBarChart() {
      eventBus.emit("draw-bar-chart");
    },
    showThicknessGraph() {
      this.generateXLabels();
      this.statistics_thickness_measurements = JSON.parse(
        JSON.stringify(this.thickness_measurements));
      this.benchmarkThicknessMeasurementsUpdateChartData();
      this.powderOutputThicknessMeasurementsUpdateChartData();
      this.thicknessMeasurementsReciprocatorStageUpdateChartData();
      this.prevAdjustmentsThicknessMeasurementsUpdateChartData();
      this.thicknessMeasurementsUpdateChartData();
      eventBus.emit("draw-gaussian-chart");
    },
  },
};
</script>
<style scoped>
.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.chart-canvas {
  max-height: 400px;
}
</style>
